import { Grid, Tooltip } from '@components';
import { PageOptions, SortOptions } from '@components/shared';
import { createElement, stringifyParams } from '@utils';
import consumer from '../channels/consumer';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = [
        'gridContainer',
        'search'
    ];

    declare gridContainerTarget: HTMLElement;
    declare searchTarget: HTMLInputElement;

    static values = {
        confirmActionFormId: String,
        url: {
            type: String,
            default: '',
        },
        organizationId: {
            type: String,
            default: ''
        }
    };

    declare urlValue: string;
    declare organizationIdValue: string;

    private grid: Grid<RunLog>;
    private parmsObj: ParamsObj = {};
    private disabled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private runLogsUpdatesSubscription: any;

    connect(): void {
        this.parmsObj.page = {
            size: 30,
            number: 1
        };

        this.createGrid();
        if (this.organizationIdValue) {
            this.runLogsUpdatesSubscription = consumer.subscriptions.create(
                {
                    channel: 'AdminRunLogsGridChannel',
                    organization_id: this.organizationIdValue,
                },
                {
                    received: this.handleRowUpdates,
                }
            );
        }

        this.reload();
    }

    private handleRowUpdates = (update: RunLog) => {
        this.grid.updateRowData(update);
    };

    private createGrid() {
        this.grid = new Grid<RunLog>({
            key: 'id',
            allowSelection: false,
            showEmptyState: true,
            noRecordsTemplate: this.noRecordsTemplate,
            container: this.gridContainerTarget,
            columns: [
                {
                    headerTitle: 'Id',
                    field: 'id',
                    width: '100px',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        return `
                            <div class="px-4 pr-6 py-2 sm:py-4 mr-auto sm:mr-0 flex items-center">
                                <span class="ow-anywhere min-w-24 pr-1"> 
                                    ${run_log.attributes.id}
                                </span>
                            </div>
                        `;
                    }
                },
                {
                    headerTitle: 'Status',
                    field: 'status',
                    sortable: false,
                    width: '200px',
                    cellTemplate: (data: RunLog) => this.createStatusCellTemplate(data)
                },
                {
                    headerTitle: 'Created At',
                    field: 'created_at',
                    width: '200px',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        const date = new Date(run_log.attributes.created_at);
                        const options: Intl.DateTimeFormatOptions = {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            timeZoneName: 'long'
                        };
                        const formattedDate = new Intl.DateTimeFormat(undefined, options).format(date);
                        return `
                            <span class="ow-anywhere min-w-24 pr-1"> 
                                ${formattedDate}
                            </span>
                        `;
                    }
                },
                {
                    headerTitle: 'Event',
                    field: 'root_type',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        const rootType = run_log.attributes.root_type
                            .split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                        return `
                        <span class="ow-anywhere min-w-24 pr-1"> 
                            ${rootType}
                        </span>
                    `;
                    }
                },
                {
                    headerTitle: 'Name',
                    field: 'name',
                    sortable: true,
                    cellTemplate: (run_log) => {
                        const name = run_log.attributes.name
                            .split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                        return `
                        <span class="ow-anywhere min-w-32"> 
                            ${name}
                        </span>
                    `;
                    }
                },
                {
                    headerTitle: 'Created By',
                    field: 'created_by',
                    sortable: false,
                    cellTemplate: (run_log) => {
                        const created_by = run_log.attributes.created_by
                            .split('_')
                            .map(word => word?.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                        return `
                        <span class="ow-anywhere min-w-24 pr-1"> 
                            ${created_by}
                        </span>
                    `;
                    }
                },
                {
                    headerTitle: 'Tree',
                    field: 'tree_view_url',
                    width: '128px',
                    sortable: false,
                    cellTemplate: (run_log) => {
                        return `
                            <div><a href="${run_log.attributes.tree_view_url}" class="border p-1 border-bkblue text-bkblue rounded" target="_blank">View</a></div>
                        `;
                    }
                }
            ],
            pageOptions: {
                number: this.parmsObj.page.number,
                size: this.parmsObj.page.size,
            },
            onSort: (sort: SortOptions) => this.sort(sort),
            onPageChange: (page: PageOptions) => this.page(page),
        });
    }

    private noRecordsTemplate = () => {
        let message = 'No records to show.';
        return `<div class="h-96 flex justify-center items-center text-gray-600 px-4">
        <div class="text-center"> ${message} </div>`;
    };

    previous(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) - 1;
        this.reload();
    }

    next(e: Event): void {
        e.preventDefault();
        if (this.disabled) return;
        this.disableButtons();
        this.parmsObj.page.number = (+this.parmsObj.page.number || 0) + 1;
        this.reload();
    }

    disableButtons(): void {
        this.disabled = true;
    }

    enableButtons(): void {
        this.disabled = false;
    }


    reload(): void {
        let url = this.urlValue || '';
        let currentPageSize = this.parmsObj.page.size
        const query = stringifyParams(this.parmsObj);
        if (query.length) {
            url = url + '?' + query;
        }
        this.ajax({
            url,
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                this.grid.pageOptions.size = currentPageSize;
                this.grid.pageOptions.number = data.meta.pagination.current;
                this.grid.totalResultsCount = data.meta.pagination.records;
                this.grid.data = data.data;

                // Trigger the window resize event to adjust grid height
                // It runs resizeHandler function defined in app/views/tailwind/shared/_core_head.html.erb
                window.dispatchEvent(new Event('resize'));
            },
        });
    }

    createStatusCellTemplate(data: RunLog) {
        let template: string;
        template = '<div class="flex-1"></div>'
        const container = createElement(template);
        if (container) {
          Object.entries(data.attributes.status_counts_by_level).forEach(([level, counts]) => {
            const progressBar = this.createProgressBar(level, counts);
            container.appendChild(progressBar);
          });
        }
        return container;
    }

    createProgressBar(level: string, counts: StatusCounts): HTMLDivElement {
        const total = Object.values(counts).reduce((sum, count) => sum + count, 0);
        const progressBar = document.createElement('div');
        progressBar.className = 'progress-bar bg-white border border-bkblue';

        let formattedToolTipOutput = Object.entries(counts).map(([key, value]) => {
            return `${value} ${key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;
        }).join('<br>');

        new Tooltip({
            element: progressBar,
            content: formattedToolTipOutput,
            class: 'whitespace-normal',
        });

        Object.entries(counts).forEach(([status, count]) => {
            const percentage = (count / total) * 100;
            const segment = document.createElement('div');
            segment.className = `progress-segment ${status}`;
            segment.style.width = `${percentage}%`
            progressBar.appendChild(segment);

            if (Object.keys(counts).length == 1){
                const label = document.createElement('span');
                label.className = 'progress-label';
                label.innerText = `Stage ${level}: ${status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`;
                segment.appendChild(label);
            }
            else{
                const label = document.createElement('span');
                label.className = 'progress-label';
                label.innerText = `${count}`;
                segment.appendChild(label);
            }
        });

        return progressBar;
    }

    animateButton(event: Event): void {
        const button = event.currentTarget as HTMLElement;
        button.classList.add('pulse');
        setTimeout(() => {
            button.classList.remove('pulse');
        }, 500); // Match the duration of the pulse animation
    }

    pageSize(e: Event): void {
        this.parmsObj.page.number = 1;
        e.preventDefault();
        this.reload();
    }

    private sort(sort: SortOptions): void {
        this.parmsObj.sort = sort.direction == 'asc' ? sort.column : '-' + sort.column
        this.reload();
    }

    private page(page: PageOptions): void {
        this.parmsObj.page = {
            number: page.number,
            size: page.size
        };
        this.reload();
    }

    disconnect() {
        super.disconnect();
    }

    search(val: string): void {
        val = val.trim();
        const currentSearch = this.parmsObj.filter?.['state_or_root_type_or_name_i_cont_any']?.replace(/\s+/g, ',')
        if (currentSearch == val || (!currentSearch && !val)) {
            return;
        }
        this.parmsObj.page.number = 1;
        this.parmsObj.filter ||= {}
        this.parmsObj.filter['state_or_root_type_or_name_i_cont_any'] = val.replace(/\s+/g, ',').toString();
        this.reload();
    }

    searchChange(): void {
        clearTimeout(+this.data.get('searchTimeout'));

        this.data.set(
            'searchTimeout',
            setTimeout(() => {
                this.search(this.searchTarget.value);
            }, 500).toString()
        );
    }
}

interface ParamsPageObj {
    number?: number
    size?: number
}

interface ParamsObj {
    search?: string;
    filter?: object;
    status?: string;
    sort?: string;
    page?: ParamsPageObj;
}

interface RunLogAttributes {
    id: number;
    state: string;
    created_at: string;
    name: string;
    root_type: string;
    created_by: string;
    tree_view_url: string;
    status_counts_by_level: StatusCountsByLevel;
}

interface RunLog {
    attributes: RunLogAttributes;
}

interface StatusCounts {
    [status: string]: number;
}

interface StatusCountsByLevel {
    [level: string]: StatusCounts;
}
